import { removeSpecialCharacters } from "../util-service/util.service";
import { links } from "../../links";
import { ROUTES } from "./navigation.routes";
import { urlSearchParamsPlaceholder } from "../../constants/navigation";

export const routes = ROUTES;

export const getCompliancePageRoute = () => `/${routes.compliance}`;


export const getName = (name) => {
	if (name) {
		name = removeSpecialCharacters(name);
		name = name.replace(/[^A-Za-z0-9]/g, '-');
	}
	return name;
};

export const getDirectorDetailsPageRoute = (id, name) => `/${routes.director}/${id}/${getName(name)}`;

export const getMovieDetailsPageRoute = (movieId, movieName) => {
	if (!movieName) return;
	let name = removeSpecialCharacters(movieName, false, true);
	name = name.replace(/[^A-Za-z0-9]/g, '-');
	return `/${routes.movie}/${movieId}/${name}`;
};

export const getSceneDetailsPageRoute = (movieId, movieName, sceneId) => {
	if (!movieName) return;
	let name = removeSpecialCharacters(movieName, false, true);
	name = name.replace(/[^A-Za-z0-9]/g, '-');
	return `/${routes.movie}/${movieId}/${name}/scene/${sceneId}`;
};

export const getJustAddedPageRoute = () => routes.justAdded;

export const getMostWatchedPageRoute = () => routes.mostWatched;

export const getTop10PageRoute = () => `/${routes.top10}`;

export const getOriginalsPageRoute = () => `/${routes.originals}?${urlSearchParamsPlaceholder}`;

export const getContentQueryPageRoute = (content, page, sort) => `?content=${content}&sort=${sort}&page=${page}`;

export const getOriginalsMoviesPageRoute = (page, sortBy) => `/${routes.originals}${getContentQueryPageRoute('Movies', page, sortBy)}`;

export const getOriginalsScenesPageRoute = (page, sortBy) => `/${routes.originals}${getContentQueryPageRoute('Scenes', page, sortBy)}`;

export const getApplicationStartRoute = () => routes.root;

export const getStarsBaseRoute = () => routes.stars;

export const getStarDetailsBaseRoute = () => getStarsBaseRoute();

export const getStarDetailsRoute = (id, name) => `${getStarDetailsBaseRoute()}/${id}/${getName(name)}`;

export const getStudioDetailsRoute = (id, name) => `/${routes.studios}/${id}/${getName(name)}?${urlSearchParamsPlaceholder}`;

export const getThemesRoute = () => `/${routes.themes}`;

export const getThemeDetailsRoute = (id, name) => `/${routes.theme}/${getName(name)}`;

export const getSexActDetailsRoute = (id, name) => `/${routes.sexAct}/${getName(name)}`;

export const getMoviePlaylistRoute = (moviePlaylistId, name) => {
	if (name) {
		name = removeSpecialCharacters(name);
	}
	return `${routes.playlists}/${moviePlaylistId}/movies/${name}`;
};

export const getScenePlaylistRoute = (scenePlaylistId, name) => {
	if (name) {
		name = removeSpecialCharacters(name);
	}
	return `${routes.playlists}/${scenePlaylistId}/scenes/${name}`;
};

export const getCustomPlaylistRoute = (playlistId, name) =>
	`${routes.playlists}/custom/${playlistId}/${removeSpecialCharacters(name)}`;

export const getMyAccountRoute = () => `/${routes.myAccount}`;

export const getNewsLetterRoute = () => `/${routes.newsletter}`;

export const getHelpRoute = () => `/${routes.help}`;

export const getTermsOfUseRoute = () => `/${routes.termsOfUse}`;

export const getPrivacyPolicyRoute = () => `/${routes.privacyPolicy}`;

export const getMyDealsRoute = () => `/${routes.mydeals}`;

export const getMemberDealsPageRoute = () => `/${routes.memberdealspage}`;

export const getMemberDealsRoute = () => `/${routes.memberDeals}`;

export const getMemberBenefitsPageRoute = () => `/${routes.memberBenefits}`;

export const getWeekendsPageRoute = () => `/${routes.weekends}`;

export const getMask4MaskRoute = () => `/${routes.mask4mask}`;

export const getParty20thRoute = () => `/${routes.party20th}`;

export const getMXPineapple2022Route = () => `/${routes.mxpineapple2022}`;

export const getFreeRoute = () => `/${routes.free}`;

export const getFreeRideOrDieBonusVideoRoute = () => `/${routes.freerideordiebonusvideo}`;

export const getFreeRideOrDieBonusBehindScenesRoute = () => `/${routes.freerideordiebonusbehindscenes}`;


export const getFreeRideOrDieBonusHadItComingRoute = () => `/${routes.freerideordiebonushaditcoming}`;

export const getFreeRideOrDieBonusMeetTheWardenRoute = () => `/${routes.freerideordiebonusmeetthewarden}`;

export const getGiftRoute = () => `/${routes.gift}`;

//export const getNewPromoSmpOneRoute = () => `/${routes.newpromosmpone}`;

//export const getNewPromoSmpTwoRoute = () => `/${routes.newpromosmptwo}`;

export const getJoinLinkRoute = () => links.joinNow;

export const getFavoritesPageRoute = () => routes.favorites;

export const getForgotPasswordPageRoute = () => `/${routes.forgotpasswordpage}`;

export const getStreamateRoute = () => routes.streamate;

export const getLiveRoute = () => routes.live;

export const getStreamBlockedRoute = () => `/${routes.streamblocked}`;

export const getFleshJackPageRoute = () => routes.fleshjack;

export const getSignInPageRoute = () => `/${routes.signin}`;

export const getViewingHistoryPageRoute = () => routes.viewingHistory;

export const getStarLetterListRoute = (letter) => `${getStarsBaseRoute()}/index/${letter}`;

export const getStarMoviesRoute = (starId, starName, page, sortBy) =>
	`${getStarDetailsRoute(starId, starName)}${getContentQueryPageRoute('Movies', page, sortBy)}`;

export const getStarScenesRoute = (starId, starName, page, sortBy) => `${getStarDetailsRoute(starId, starName)}?content=Scenes&sort=${sortBy}&page=${page}`;

export const getDirectorScenesRoute = (id, name, page, sortBy) =>
	`${getDirectorDetailsPageRoute(id, name)}${getContentQueryPageRoute('Scenes', page, sortBy)}`;

export const getDirectorMoviesRoute = (id, name, page, sortBy) =>
	`${getDirectorDetailsPageRoute(id, name)}${getContentQueryPageRoute('Movies', page, sortBy)}`;

export const getTrentonDucatiOriginalsRoute = () => `/${routes.trentonducatioriginals}`;